<template>
    <div class="mobile-page-layout static-pages-layout blue-layout">
        <div class="mobile-page-header">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <div @click="back()" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </div>
                    <div class="mobile-page-header-icon">
                        <div class="mobile-page-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="header-svg-icon">
                                <path d="M352 352H96c-53 0-96 43-96 96v32c0 17.62 14.38 32 32 32h96v-64c0-17.62 14.38-32 32-32h128c17.62 0 32 14.38 32 32v64h96c17.62 0 32-14.38 32-32v-32C448 395 405 352 352 352zM116 320h216c19.88 0 36-16.12 36-36V109C368 84.13 347.9 64 323 64h-198C100.1 64 80 84.13 80 109V284C80 303.9 96.13 320 116 320zM304 272h-32v-32h32V272zM288 128c17.62 0 32 14.38 32 32s-14.38 32-32 32s-32-14.38-32-32S270.4 128 288 128zM208 240h32v32h-32V240zM176 272h-32v-32h32V272zM160 128c17.62 0 32 14.38 32 32S177.6 192 160 192S128 177.6 128 160S142.4 128 160 128z" class="fa-primary"></path>
                                <path d="M208 272h32v-32h-32V272zM176 448C167.1 448 160 455.1 160 464V512h32v-48C192 455.1 184.9 448 176 448zM176 240h-32v32h32V240zM37.25 133.3C33.88 136.6 32 141.3 32 146v92c0 4.75 1.875 9.375 5.25 12.75S45.25 256 49.1 256H80V128H49.1C45.25 128 40.63 129.9 37.25 133.3zM410.8 133.3C407.4 129.9 402.8 128 397.1 128H368v128h29.1c4.75 0 9.375-1.875 12.75-5.25S416 242.8 416 238v-92C416 141.3 414.1 136.6 410.8 133.3zM272 448c-8.875 0-16 7.125-16 16s7.125 16 16 16s16-7.125 16-16S280.9 448 272 448zM272 272h32v-32h-32V272zM224 0C215.2 0 208 7.162 208 16V64h32V16C240 7.162 232.8 0 224 0z" class="fa-secondary"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="mobile-page-header-text">ربات‌های پاسخگو</div>  

                </div>
            </div>
        </div>

        
        <div class="static-pages-content"  style="padding: 0 5px;" v-if="tab == 0">
            <div class="home-layout">
                <div class="social-cover color-whatsapp">
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/whatsapp-cover.png">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.216 175.552" style="width: 34px;  margin-top: -3px;max-width: unset;margin-left: -3px;"><defs><linearGradient id="b" x2="86.535" y1="32.567" y2="137.092" gradientUnits="userSpaceOnUse" x1="85.915"><stop offset="0" stop-color="#57d163"></stop><stop offset="1" stop-color="#23b33a"></stop></linearGradient></defs><path filter="url(#a)" fill="#b3b3b3"></path><path fill="url(#linearGradient1780)" d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.559 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.524h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.929z"></path><path fill="url(#b)" d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.313-6.179 22.558 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.517 31.126 8.523h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.928z"></path><path fill="#fff" fill-rule="evenodd" d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"></path></svg>
                            </div>
                            <h2>واتس اپ</h2>
                        </div>
                        <p>

                        </p>
                        <div class="social-cover-btn">
                            <a href="https://wa.me/989120407489" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>

                <div class="social-cover color-telegram">
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/telegram-cover.png?03">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 240 240" style="" data-name="telegram"><circle cx="120" cy="120" r="120" fill="url(#linear-gradient)" style="fill: #2198d5;"></circle><path d="M81.229,128.772l14.237,39.406s1.78,3.687,3.686,3.687,30.255-29.492,30.255-29.492l31.525-60.89L81.737,118.6Z" fill="#c8daea"></path><path d="M100.106,138.878l-2.733,29.046s-1.144,8.9,7.754,0,17.415-15.763,17.415-15.763" fill="#a9c6d8"></path><path d="M81.486,130.178,52.2,120.636s-3.5-1.42-2.373-4.64c.232-.664.7-1.229,2.1-2.2,6.489-4.523,120.106-45.36,120.106-45.36s3.208-1.081,5.1-.362a2.766,2.766,0,0,1,1.885,2.055,9.357,9.357,0,0,1,.254,2.585c-.009.752-.1,1.449-.169,2.542-.692,11.165-21.4,94.493-21.4,94.493s-1.239,4.876-5.678,5.043A8.13,8.13,0,0,1,146.1,172.5c-8.711-7.493-38.819-27.727-45.472-32.177a1.27,1.27,0,0,1-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6,53.821-51.492c.108-.379-.3-.566-.848-.4-3.482,1.281-63.844,39.4-70.506,43.607A3.21,3.21,0,0,1,81.486,130.178Z" fill="#fff"></path></svg>
                            </div>
                            <h2>تلگرام</h2>
                        </div>
                        <p>
                        </p>
                        <div class="social-cover-btn">
                            <a href="https://t.me/TGJU_Support_bot" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>

                <div class="social-cover color-facebook-messenger">
                    
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/facebook-messenger-cover.png?01">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800"><radialGradient id="a" cx="101.9" cy="809" r="1.1" gradientTransform="matrix(800 0 0 -800 -81386 648000)" gradientUnits="userSpaceOnUse"><stop offset="0" style="stop-color:#09f"></stop><stop offset=".6" style="stop-color:#a033ff"></stop><stop offset=".9" style="stop-color:#ff5280"></stop><stop offset="1" style="stop-color:#ff7061"></stop></radialGradient><path fill="url(#a)" d="M400 0C174.7 0 0 165.1 0 388c0 116.6 47.8 217.4 125.6 287 6.5 5.8 10.5 14 10.7 22.8l2.2 71.2a32 32 0 0 0 44.9 28.3l79.4-35c6.7-3 14.3-3.5 21.4-1.6 36.5 10 75.3 15.4 115.8 15.4 225.3 0 400-165.1 400-388S625.3 0 400 0z"></path><path fill="#FFF" d="m159.8 501.5 117.5-186.4a60 60 0 0 1 86.8-16l93.5 70.1a24 24 0 0 0 28.9-.1l126.2-95.8c16.8-12.8 38.8 7.4 27.6 25.3L522.7 484.9a60 60 0 0 1-86.8 16l-93.5-70.1a24 24 0 0 0-28.9.1l-126.2 95.8c-16.8 12.8-38.8-7.3-27.5-25.2z"></path></svg>
                            </div>
                            <h2>فیس بوک مسنجر</h2>
                        </div>
                        <p>

                        </p>
                        <div class="social-cover-btn">
                            <a href="https://m.me/TgjuSocial" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>

                <div class="social-cover color-line">
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/line-cover.png?01">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320"><g id="Layer_2" data-name="Layer 2"><g id="LINE_LOGO" data-name="LINE LOGO"><rect class="cls-1" width="320" height="320" rx="72.14"></rect><path class="cls-2"  fill="#06c755" d="M266.66,144.92c0-47.74-47.86-86.58-106.69-86.58S53.28,97.18,53.28,144.92c0,42.8,38,78.65,89.22,85.42,3.48.75,8.21,2.29,9.4,5.26,1.08,2.7.71,6.93.35,9.65,0,0-1.25,7.53-1.52,9.13-.47,2.7-2.15,10.55,9.24,5.76s61.44-36.18,83.82-61.95h0C259.25,181.24,266.66,164,266.66,144.92Z"></path><path class="cls-1" d="M231.16,172.49h-30a2,2,0,0,1-2-2v0h0V123.94h0v0a2,2,0,0,1,2-2h30a2,2,0,0,1,2,2v7.57a2,2,0,0,1-2,2H210.79v7.85h20.37a2,2,0,0,1,2,2V151a2,2,0,0,1-2,2H210.79v7.86h20.37a2,2,0,0,1,2,2v7.56A2,2,0,0,1,231.16,172.49Z"></path><path class="cls-1" d="M120.29,172.49a2,2,0,0,0,2-2v-7.56a2,2,0,0,0-2-2H99.92v-37a2,2,0,0,0-2-2H90.32a2,2,0,0,0-2,2v46.53h0v0a2,2,0,0,0,2,2h30Z"></path><rect class="cls-1" x="128.73" y="121.85" width="11.64" height="50.64" rx="2.04"></rect><path class="cls-1" fill="#fff" d="M189.84,121.85h-7.56a2,2,0,0,0-2,2v27.66l-21.3-28.77a1.2,1.2,0,0,0-.17-.21v0l-.12-.12,0,0-.11-.09-.06,0-.11-.08-.06,0-.11-.06-.07,0-.11,0-.07,0-.12,0-.08,0-.12,0h-.08l-.11,0h-7.71a2,2,0,0,0-2,2v46.56a2,2,0,0,0,2,2h7.57a2,2,0,0,0,2-2V142.81l21.33,28.8a2,2,0,0,0,.52.52h0l.12.08.06,0,.1.05.1,0,.07,0,.14,0h0a2.42,2.42,0,0,0,.54.07h7.52a2,2,0,0,0,2-2V123.89A2,2,0,0,0,189.84,121.85Z"></path></g></g></svg>
                            </div>
                            <h2>لاین</h2>
                        </div>
                        <p>
                        </p>
                        <div class="social-cover-btn">
                            <a href="https://line.me/R/ti/p/@ysg2920z" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>

                <div class="social-cover color-viber">
                    
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/viber-cover.png?02">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0Z" fill="#7F4DA0"></path>
                                    <path d="M30.3941 20.9584C30.2774 20.0101 29.9859 19.2655 29.4683 18.6059C28.7961 17.7566 27.9372 17.2481 26.6655 16.9464C25.8367 16.7453 25.5019 16.7684 25.2022 17.0422C24.923 17.2987 24.8685 17.8129 25.0818 18.146C25.2319 18.3877 25.405 18.4742 25.8864 18.5687C26.5275 18.6867 26.9706 18.8398 27.3736 19.0702C28.229 19.5642 28.6274 20.3371 28.6701 21.5885C28.69 22.176 28.7408 22.3566 28.946 22.565C29.3263 22.9459 30.0032 22.8719 30.2904 22.416C30.3957 22.2442 30.4118 22.1694 30.4229 21.7705C30.4303 21.5235 30.4196 21.1558 30.3941 20.9584V20.9584Z" fill="white"></path>
                                    <path d="M33.7446 22.0277C33.5757 19.1223 32.4393 16.7848 30.4055 15.1402C29.2596 14.216 27.8745 13.5806 26.3563 13.2716C25.8001 13.161 24.7762 13.0715 24.6005 13.1227C24.4347 13.1702 24.2003 13.3496 24.0958 13.512C23.9852 13.688 23.9603 14.081 24.0546 14.3083C24.2068 14.6883 24.4898 14.8226 25.2826 14.892C26.5047 14.9989 27.7652 15.4282 28.7176 16.0639C29.8049 16.7876 30.6693 17.7775 31.2284 18.9407C31.7116 19.9402 32.0087 21.3805 32.0005 22.6703C31.9977 23.1367 32.0698 23.4002 32.2573 23.5928C32.5405 23.8889 32.9533 23.9537 33.3176 23.757C33.7213 23.5447 33.8157 23.172 33.7446 22.0277V22.0277Z" fill="white"></path>
                                    <path d="M37.4522 33.2163C37.3954 33.0502 37.2628 32.7941 37.1681 32.647C36.5712 31.7457 33.3645 29.2362 31.7635 28.4155C30.8493 27.9459 30.172 27.7894 29.6699 27.9363C29.1299 28.0882 28.8457 28.3396 27.941 29.4639C27.5763 29.9146 27.2021 30.3368 27.1026 30.4032C26.8563 30.574 26.3731 30.7116 26.0321 30.7116C25.2411 30.7068 23.8059 30.1945 22.7022 29.5208C21.8449 28.999 20.746 28.0645 19.9028 27.1394C18.9081 26.0531 18.2308 25.0948 17.6955 24.0132C17.004 22.6233 16.8287 21.7741 17.1176 21.1526C17.1887 20.9961 17.2835 20.8301 17.326 20.7779C17.3687 20.7305 17.814 20.3652 18.3065 19.9667C19.2729 19.1982 19.4292 19.0227 19.6091 18.4961C19.8365 17.8272 19.775 17.1441 19.4244 16.4563C19.1544 15.9344 18.4487 14.7959 17.9371 14.0606C17.2598 13.0929 15.5735 11.0246 15.2371 10.7446C14.6309 10.2513 13.8541 10.1659 12.9967 10.498C12.0921 10.849 10.3963 12.2153 9.67155 13.1688C9.01789 14.0322 8.76692 14.6441 8.72899 15.4505C8.69586 16.1147 8.75268 16.3898 9.1032 17.239C11.8457 23.8993 15.9382 29.2172 21.5607 33.4393C24.4974 35.6451 27.5431 37.3245 30.7025 38.4726C32.545 39.1414 33.3455 39.1746 34.2881 38.6244C34.686 38.3871 35.6712 37.3767 36.2207 36.6414C37.1301 35.4174 37.4665 34.853 37.5612 34.4023C37.6275 34.0939 37.5754 33.5579 37.4522 33.2163V33.2163Z" fill="white"></path>
                                    <path d="M37.1555 22.6949C37.0948 21.0515 36.8181 19.7035 36.2411 18.2005C35.6715 16.7222 35.095 15.7574 34.0062 14.4526C32.9769 13.2292 32.1773 12.5306 30.9093 11.7531C29.1402 10.6697 26.9872 9.95272 24.5744 9.6512C23.7516 9.54582 23.5654 9.55335 23.313 9.72179C22.8238 10.0409 22.8303 10.8367 23.321 11.151C23.4835 11.251 23.6276 11.2875 24.2858 11.3736C25.2962 11.5096 25.9459 11.6378 26.7168 11.8482C29.7398 12.6745 32.0645 14.3637 33.6187 16.8712C34.9193 18.9611 35.4592 21.0513 35.3826 23.7208C35.3559 24.6232 35.3753 24.782 35.5132 24.9989C35.7774 25.403 36.4741 25.483 36.8315 25.1476C37.0539 24.9432 37.0958 24.7898 37.1396 24.0174C37.1606 23.6144 37.1651 23.0196 37.1555 22.6949V22.6949Z" fill="white"></path>
                                </svg>
                            </div>
                            <h2>وایبر</h2>
                        </div>
                        <p>
                        </p>
                        <div class="social-cover-btn">
                            <a href="https://whelp.link/r/969b7d6de9" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>

                <div class="social-cover color-slack">
                    
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/slack-cover.png?02">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130">
                                    <path d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z" fill="#E01E5A"></path>
                                    <path d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z" fill="#36C5F0"></path>
                                    <path d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z" fill="#2EB67D"></path>
                                    <path d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z" fill="#ECB22E"></path>
                                </svg> -->
                            </div>
                            <h2>اِسلَک</h2>
                        </div>
                        <p>
                        </p>
                        <div class="social-cover-btn">
                            <a href="https://slack.com/oauth/authorize?scope=bot,%20bot,%20bot&client_id=281417181894.292043696246&redirect_uri=https://smapi.gupshup.io/sm/api/slackwebhook/oauth/TGJUBot" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>

                
                <div class="social-cover color-skype">
                    
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/skype-cover.png?01">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130">
                                    <path d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z" fill="#E01E5A"></path>
                                    <path d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z" fill="#36C5F0"></path>
                                    <path d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z" fill="#2EB67D"></path>
                                    <path d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z" fill="#ECB22E"></path>
                                </svg> -->
                            </div>
                            <h2>اسکایپ</h2>
                        </div>
                        <p>
                        </p>
                        <div class="social-cover-btn">
                            <a href="https://link.tgju.org/skype" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>

                
                <div class="social-cover color-microsoft">
                    
                    <div class="social-cover-img">
                        <img src="https://www.tgju.org/landing/assets/images/covers/teams-cover.png?01">
                    </div>
                    <div class="social-cover-content">
                        <div class="social-cover-title">
                            <div class="social-cover-logo">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130">
                                    <path d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z" fill="#E01E5A"></path>
                                    <path d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z" fill="#36C5F0"></path>
                                    <path d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z" fill="#2EB67D"></path>
                                    <path d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z" fill="#ECB22E"></path>
                                </svg> -->
                            </div>
                            <h2>مایکروسافت تیمز</h2>
                        </div>
                        <p>
                        </p>
                        <div class="social-cover-btn">
                            <a href="https://link.tgju.org/microsoft-teams" target="_blank" class="tgju-btn tgju-btn-primary">مشاهده</a>
                        </div>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>


<script>
    export default {
        name: 'Robot',
        components: {},
        data: function () {
            return {
                tab:0
            }
        },
        mounted() {},
        computed: {},
        methods: {
            // دکمه بازگشت
            back() {
                this.$router.go(-1);
            },
        },
    }
</script>